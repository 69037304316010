import { MdClose } from "react-icons/md";
import { IProject } from "../models";
import "../styles/ProjectOverlay.css";

interface IProps {
  project: IProject;
  setCurrentProject: (project: IProject | null) => void;
}

const ProjectDescription: React.FC<{ project: IProject }> = ({ project }) => {
  return (
    <>
      {project.description.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </>
  );
};

const ProjectArchitecture: React.FC<{ project: IProject }> = ({ project }) => {
  return (
    <>
      <h1>architecture</h1>
      <hr />
      {project.architecture.map((arch, index) => (
        <p key={index}>{arch}</p>
      ))}
    </>
  );
};

const ProjectTech: React.FC<{ project: IProject }> = ({ project }) => {
  return (
    <>
      <ul>
        {project.tech.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>
    </>
  );
};

export const ProjectOverlay: React.FC<IProps> = ({
  project,
  setCurrentProject,
}) => {
  return (
    <div className="ProjectOverlay">
      <div
        id="closeProject"
        title="Close"
        onClick={() => {
          setCurrentProject(null);
        }}
      >
        <MdClose />
      </div>

      <div className="project">
        <h1 id="project-heading">{project.name}</h1>

        <div className="text-container">
          <h1>
            overview
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              <span
                className="highlight"
                style={{ fontSize: "18px", marginLeft: "10px" }}
              >
                go to this project
              </span>
            </a>
          </h1>
          <hr />

          <ProjectDescription project={project} />
          <ProjectArchitecture project={project} />
          <ProjectTech project={project} />
        </div>
      </div>
    </div>
  );
};
