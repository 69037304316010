import React from "react";
import "../styles/Home.css";

const baseUrl = window.location.href.includes("localhost")
  ? "http://localhost:3000/#/"
  : "https://pscott.io/#/";

const frameSource = window.location.href.includes("localhost")
  ? "http://localhost:8080/"
  : "https://particle-frame.herokuapp.com/";

function postMessageHandler(message) {
  if (message.data === "portfolio" || message.data === "about")
    window.location.href = `${baseUrl}${message.data}`;
}

export function Home() {
  React.useEffect(() => {
    window.addEventListener("message", postMessageHandler);
  }, []);

  React.useEffect(() => {
    const newWindowLocation = window.location.href.split("/").slice(-1)[0];

    // Destroy postMessage listener as soon as navigating away from home page (before animations)
    if (["portfolio", "about"].includes(newWindowLocation)) {
      window.removeEventListener("message", postMessageHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return <iframe title="particle iframe" src={frameSource}></iframe>;
}
