import React, { useState } from "react";
import { ProjectOverlay } from "../components/ProjectOverlay";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import "../styles/Portfolio.css";
import { projects } from "../assets/portfolioData";
import { IProject } from "../models";

function HomeButton() {
  return (
    <Link id="closePortfolio" to="/" title="Home">
      <IoIosArrowBack style={{ fontSize: "24px" }} />
      <span>Home</span>
    </Link>
  );
}

interface IProjectListProps {
  projects: IProject[];
  setCurrentProject: (project: IProject) => void;
}

const ProjectList: React.FC<IProjectListProps> = ({
  projects,
  setCurrentProject,
}) => {
  return (
    <>
      {projects.map((block, index) => (
        <div
          className="blocks"
          key={index}
          onClick={(event) => {
            setCurrentProject(
              projects.filter(
                (proj) => proj.name === event.currentTarget.innerText
              )[0]
            );
          }}
        >
          <img alt={block.name} src={"images/" + block.img} />
          <div className="overlay">
            <span>{block.name}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export function Portfolio() {
  const [currentProject, setCurrentProject] = useState<IProject | null>(null);

  return (
    <div className="Portfolio">
      {!currentProject && <HomeButton />}

      <div className="grid">
        <ProjectList
          projects={projects}
          setCurrentProject={setCurrentProject}
        />
      </div>

      {currentProject != null && (
        <ProjectOverlay
          project={currentProject}
          setCurrentProject={setCurrentProject}
        />
      )}
    </div>
  );
}
