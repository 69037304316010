import { aboutMe } from "../assets/portfolioData";
import "../styles/About.css";

const homeUrl = window.location.href.includes("localhost")
  ? "http://localhost:3000/#/"
  : "https://pscott.io/#/";

const { youtubeLink, blogLink, emailLink, githubLink, musicLink, resumeLink } =
  aboutMe;

export function About() {
  return (
    <div className="About">
      <div className="text-container">
        <h3>
          const <span className="highlight">patrick </span>=
        </h3>

        <p>
          I'm a<span className="highlight"> senior software engineer </span>
          based in Columbia, SC. I teach JavaScript-focused tutorials on{" "}
          <a
            className="highlight underline"
            target="_blank"
            rel="noreferrer noopener"
            href={youtubeLink}
          >
            YouTube
          </a>{" "}
          and I make web development easy to understand via my blog -{" "}
          <a
            className="highlight underline"
            target="_blank"
            rel="noreferrer noopener"
            href={blogLink}
          >
            explainedsimply.io
          </a>
          .
        </p>

        <p>
          I enjoy studying biblical literature and{" "}
          <a href={musicLink} target="_blank" rel="noreferrer noopener">
            <span className="highlight underline"> playing piano</span>
          </a>
          . Take a look at my{" "}
          <a href={resumeLink} target="_blank" rel="noreferrer noopener">
            <span className="highlight underline">resume</span>
          </a>{" "}
          and my{" "}
          <a href={githubLink} target="_blank" rel="noreferrer noopener">
            <span className="highlight underline">GitHub</span>
          </a>{" "}
          account. You can contact me at
          <a href={`mailto:${emailLink}`}>
            {" "}
            <span className="highlight underline">{emailLink}</span>
          </a>
          .
        </p>

        <h3 style={{ fontSize: "40px" }}>;</h3>
        <p id="back-home">
          <a href={`${homeUrl}`}>&lt; back home</a>
        </p>
      </div>
    </div>
  );
}
