import { Route, useLocation, Routes } from "react-router-dom";
import { Home, Portfolio, About } from "../pages";
import { animated, useTransition } from "react-spring";
import "../styles/App.css";

export default function App() {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1, position: "absolute" },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  });

  return transitions((styles, item) => {
    return (
      <animated.div style={styles}>
        <Routes location={item}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </animated.div>
    );
  });
}
